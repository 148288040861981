/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

.logo-main {
  margin: 0 auto;
  width: 500px;
  padding: 20px 0 20px 0;
}

@media screen and (max-width: 992px) {
  .logo-main {
    width: 300px;
  }
}

@media screen and (max-width: 480px) {
  #section-spacing{
      margin-top: 80px;
    }
}

.main-section {
  text-align: center;
  margin-bottom: 60px;
}

.main-paragraph {
  padding-top: 25px;
  padding-bottom: 25px;
  color: darkgrey;
}

.parallax-container {
  background-image: url("../../img/parallax.JPG");
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#txt-container{
  height: 500px;
  text-align: center;
  line-height: 166px;
}

.my-text{
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  color: white;
  font-size: 5em;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .my-text{
    font-size: 3em;
  }
}


.main-overview{
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
}

.icon-overview{
    font-size: 100px;
    color: darkgrey;
    transition: 0.2s;
}

.overview-heading{
    margin-top: 40px;
    font-size: 24px;
}

.overview-card{
    border-radius: 15px;
    transition: 0.2s;
    padding: 10px;
    transform: scale(1.0);
}

.overview-card:hover{
    border-radius: 15px;
    box-shadow: 1px 1px 10px lightgray;
    transition: 0.2s;
    
}

.overview-card:hover > .icon-overview{
    color: #427851;
    transition: 0.2s;
    transform: scale(1.1);
}