/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/
.my-text-news{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    color: white;
    font-size: 5em;
}

@media screen and (max-width: 480px) {
    .my-text-news{
        font-size: 3.5em;
      }
  }

.parallax-container-news{
    background-image: linear-gradient(rgba(255,255,255,0.2), rgba(255, 255, 255, 0.5)), url("../../img/parallax2.jpg");
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #txt-news-container{
    height: 500px;
    text-align: center;
    line-height: 500px;
  }

  .news-img{
      width: 100%;
      border-radius: 50%;
  }

  .news-entry{
      margin: 40px;
      padding: 40px 0px;
      border-top: 1px dotted lightgray;
  }

  .right-al{
      text-align: right;
  }

  h1.display-4{
      font-size: 2.5em;
  }

