/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/
.footer{
    background-color: #427851;
    color: white;
    height: auto;
    padding-top: 40px;
    width: 100%;
    overflow: hidden;
}

.footer-text{
    color: #203627;
    margin: 0;
    width: 100%;
    display: block;
}

#hov:hover{
    font-weight: 500;
    color: #203627;
}

.footer-heading{
    font-size: 20px !important;
}

.footer-disclaimer{
    margin-top: 40px;
    padding: 5px;
    width: 100%;
    background-color: #203627;
    text-align: center;
}

.footer-disclaimer .footer-heading{
    margin: 0;
    display: inline-block;
}

.footer-disclaimer a, .footer-disclaimer a:visited{
    color: whitesmoke;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}