/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

.heading-container {
  display: block;
  height: 60px;
  width: 100%;
  z-index: 99;
  background-color: #427851;
  padding-top: 20px;
  margin-top: 80px;
}

#instalogo{
    height: 1em;
    width: 1em;
    float: right;
    display: inline-block;
  }

  .heading-text{
      color: white;
      display: inline-block;
      float: left;
      margin: 0px 5px 0px 5px;
  }

  .heading-text:hover{
    color: white;
    text-decoration: none;
}

.heading-text:active{
  color: white;
  text-decoration: none;
}

  .heading-content-wrapper{
      width: 60%;
      margin: 0 auto;
  }

  @media screen and (max-width: 992px) {
    .heading-content-wrapper{
        width: 80%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 480px) {
    .heading-container {
        display: none;
      }
}