/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

#product-ctn{
    text-align: center;
    margin: 35px 0px;
}

@media screen and (max-width: 580px) {
    #product-ctn{
        margin-top: 80px;
      }
  }

#product-ctn a{
    text-decoration: none;
}

.parallax-container-product{
    height: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    text-align: left;
    opacity: 0.5 !important;
    margin: 20px 0px;
    transition: 0.2s;
  }

  .parallax-container-product:hover{
    opacity: 1 !important;
    transition: 0.2s;
    transform: scale(1.01) !important;
  }
  
  .text-container-product {
    height: 200px;
    line-height: 200px;
    font-size: 3.5em;
    color: white;
    margin-left: 20px;
  }
  .prod-calender{
    height:  200px;
  }

  #strawberry{
    background-image: url("../../img/products/strawberry.jpg");
  }

  #potato{
    background-image: url("../../img/products/potato.jpg");
  }

  #fisoles{
    background-image: url("../../img/products/fisoles.jpg");
  }

  #pumpkin{
    background-image: url("../../img/products/pumpkin.jpg");
  }

  #cheery{
    background-image: url("../../img/products/cheery.jpg");
  }

  #beet{
    background-image: url("../../img/products/beets.jpg");
  }

  #zucchini{
    background-image: url("../../img/products/zucchini.jpg");
  }

  #liquor{
    background-image: url("../../img/products/liquor.jpg");
  }


  #strawberrycalender, #beetcalender, #fisolscalender, #potatocalender, #pumpkincalender, #cheerycalender, #zuchinicalender{
    width: 99%;
  }

#saisontitle{
  font-size: 2.8em;
}