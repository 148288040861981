/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

.prod-ctn-simple{
    margin-bottom: 35px;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .prod-ctn-simple{
        margin-top: 80px;
      }
  }

.prod-simple-head{
    margin-top: 20px;
}

.parallax-container-product-simple{
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#carousel{

}

#carousel-wrap{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transform: scale(0.5);
    margin: 0px;
    height: 540px;
}

.description{
    height: 200px;
}

.prod-calender{
    margin-top: 100px;
    height: 70%;
}

.calender-text{
    height: 200px;
    line-height: 200px;
    font-size: 2.5em;
}