/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

#nav-wrap{
	box-shadow: 0px 1px 10px gray !important;
}

*,
*:after,
*::before {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.nav-logo{
    width: 100px;
    margin-left: 50px;
}

.my-nav-link{
    margin-right: 20px !important;
    overflow: hidden !important;
	padding: 0 4px !important;
    height: 1.2em !important;
    position: relative !important;
	display: inline-block !important;
	margin: 15px 25px;
	outline: none !important;
	text-decoration: none !important;
	text-transform: uppercase !important;
	letter-spacing: 1px !important;
	font-weight: 400 !important;
	text-shadow: 0 0 1px rgba(255,255,255,0.3) !important;
	font-size: 1em !important;
}

.my-nav-link-active{
    box-shadow: 0px 2px 0px #427851 !important;
	font-weight: 500 !important;
	padding-bottom: 30px !important;
}

.my-nav-link span{
    position: relative !important;
	display: inline-block !important;
	-webkit-transition: -webkit-transform 0.3s !important;
	-moz-transition: -moz-transform 0.3s !important;
	transition: transform 0.3s !important;
}

.my-nav-link span::before{
    position: absolute !important;
	top: 100% !important;
	content: attr(data-hover) !important;
	font-weight: 400 !important;
	-webkit-transform: translate3d(0,0,0) !important;
	-moz-transform: translate3d(0,0,0) !important;
    transform: translate3d(0,0,0) !important;
}


.my-nav-link:hover span,
.my-nav-link:focus span{
    -webkit-transform: translateY(-100%) !important;
	-moz-transform: translateY(-100%) !important;
    transform: translateY(-100%) !important;
}

.my-nav-drop{
	margin-right: 0px !important;
	padding: 0 4px !important;
    height: 1.2em !important;
    position: relative !important;
	display: inline-block !important;
	margin: 7px 2px;
	outline: none !important;
	text-decoration: none !important;
	text-transform: uppercase !important;
	letter-spacing: 1px !important;
	font-weight: 400;
	text-shadow: 0 0 1px rgba(255,255,255,0.3) !important;
	font-size: 1em !important;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(167, 167, 167) !important;
}

@media screen and (max-width: 991px) {
    .my-nav-drop{
		margin-left: 25px;
		margin-bottom: 20px;
      }
  }

.my-drop-item:active{
	background-color: #427851 !important;
}

.my-drop-item{
	z-index: 10000 !important;
}

.show{
	z-index: 10000 !important;
}