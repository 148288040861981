
#loader-ctn{
  height: 100vh;
  width: 100vw;
  position: relative;
  -webkit-user-select: none; 
  -moz-user-select: none;   
  -ms-user-select: none;    
  user-select: none;      
}

.traktor-ctn{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#radhinten{
  animation: rotation 5s infinite linear;
  position: absolute;
  top: 23px;
  left: -20.5px;
}

#radvorne{
    animation: rotation 4s infinite linear;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 35px;
    left: 34px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}