/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

 h3{
   color: #427851;
 }

 