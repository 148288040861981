/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

.team{
    background-image: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,1)), url("../../img/pinwand.png");
    text-align: center;
    padding-bottom: 35px;
}

@media screen and (max-width: 480px) {
    .team{
        margin-top: 80px;
      }
  }

.team > h1{
    padding-top: 35px;
    margin: 0 !important;
}

.news-img{
    width: 100%;
    border-radius: 50%;
}

.news-entry{
    margin: 40px;
    padding: 40px 0px;
    border-top: 1px dotted lightgray;
}

.right-al{
    text-align: right;
}


.familypic{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#abouth{
    color: #427851;
}

#familypic{
    width: 50%;
}

@media screen and (max-width: 768px) {
    #familypic{
        width: 90%;
    }
  }




