/* 
BRENNER Alexander, GRILL Alexander @ 2020

genussvomfeld Website
*/

.contact-div{
  text-align: center;
  padding: 20px;
  margin: 40px;
}

@media screen and (max-width: 480px) {
  .contact-div{
      margin-top: 80px;
    }
}

.contact-div > p{
  margin: 0;
}

#contact-section{
  border-bottom: 1px dotted lightgray;
}

.box{
    color: #427851;
    font-size: 30px;
    text-align: center;
}

  .parallax-container-vh{
    background-image: url("../../img/amHof.jpg");
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
  }

  .parallax-container-ve{
    background-image: url("../../img/wahrendErd.jpg");
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
  }

  #txt-contact-container{
    height: 500px;
    text-align: center;
    line-height: 500px;
  }

.contact-text{
  margin: 30px 0px 30px 0px;
}

#mes{
    height: 10em;
}

.contact-container{
  text-align: center;
  margin: auto;
  width: 50%;
}

@media screen and (max-width: 565px) {
  .contact-container{
    text-align: center;
    margin: auto;
    width: 90%;
  }
}

#contact-wrap{
  width: 100%;
}

.my-button{
  margin: 35px 0px;
  color: #427851;
  padding: 5px;
  border: 1px solid #427851;
  border-radius: 5px;
  background-color: rgba(0,0,0,0);
  transition: 0.1s;
}
.my-button:focus{
  outline: none;
}

.my-button:hover{
  color: white;
  background-color: #427851;
  border: 1px solid #427851;
  transition: 0.1s;
}

.map-desc{
  padding: 20px;
  height: auto;
}

.my-map-wrap{
  width: 100%;
  height: 250px;
}

.my-right-align{
  text-align: right;
}

.input-group:nth-child(3){
  height: 200px;
}

#med {
  resize: none;
}

textarea{
  resize: none !important;
}

#user-feedback{
  width: 50%;
  margin: 30px auto;
  visibility: hidden;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #user-feedback{
    width: 95%;
  }
}

#form{
  margin-top: 8em;
}

#anfahrttitle,#anfahrttitle2{
  font-size: 2.8em;
}


#name, #email{
  border-radius: 3px;
}